// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-bonus-index-js": () => import("./../../../src/pages/bonus/index.js" /* webpackChunkName: "component---src-pages-bonus-index-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-materiais-js": () => import("./../../../src/pages/materiais.js" /* webpackChunkName: "component---src-pages-materiais-js" */),
  "component---src-pages-paginas-ebooks-palavras-chaves-negativas-download-js": () => import("./../../../src/pages/paginas/ebooks/palavras-chaves-negativas/download.js" /* webpackChunkName: "component---src-pages-paginas-ebooks-palavras-chaves-negativas-download-js" */),
  "component---src-pages-paginas-ebooks-palavras-chaves-negativas-index-js": () => import("./../../../src/pages/paginas/ebooks/palavras-chaves-negativas/index.js" /* webpackChunkName: "component---src-pages-paginas-ebooks-palavras-chaves-negativas-index-js" */),
  "component---src-pages-paginas-ebooks-palavras-chaves-negativas-obrigado-js": () => import("./../../../src/pages/paginas/ebooks/palavras-chaves-negativas/obrigado.js" /* webpackChunkName: "component---src-pages-paginas-ebooks-palavras-chaves-negativas-obrigado-js" */),
  "component---src-pages-pesquisar-js": () => import("./../../../src/pages/pesquisar.js" /* webpackChunkName: "component---src-pages-pesquisar-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-recomendacao-js": () => import("./../../../src/pages/recomendacao.js" /* webpackChunkName: "component---src-pages-recomendacao-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sucesso-js": () => import("./../../../src/pages/sucesso.js" /* webpackChunkName: "component---src-pages-sucesso-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */),
  "component---src-pages-williandsantos-js": () => import("./../../../src/pages/williandsantos.js" /* webpackChunkName: "component---src-pages-williandsantos-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

